import * as React from 'react';
// @ts-ignore
import map from 'lodash/map';
// @ts-ignore
import get from 'lodash/get';
import { Link } from 'gatsby';
import Img from "gatsby-image";
import styled from 'styled-components';
import { graphql } from "gatsby"
import Layout from '../components/Layout';
import PageContent from '../components/PageContent';
import { mq, colors } from "../common/styles";
import CallToAction from "../components/CallToAction";
import TestimonialsWidget from '../components/TestimonialsWidget';

const CardContainer = styled.div`
  display: flex; 
  flex-direction: column;
  ${mq.laptop} {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
  }
`;

const Card = styled.div`
  position: relative;
  margin: 8px 0;
  ${mq.laptop} {
    width: 460px;
    margin: 16px 16px;
  }
  .caption {
    z-index: 2;
    position: absolute;
    bottom: 0;
    right: 8px;
    background: rgba(0, 0, 0, 0.6);
    transition: .25s ease-in-out;
    padding: 4px 12px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    color: white;
    text-align: right;
    .title {
      height: 18px;
      font-weight: 500;
      letter-spacing: 0.5px;
    }
    .type {
      font-size: 11px;
      color: #bbb;
    }
  }
  .pic {
    transition: .3s ease-in-out;
  }
  .picClip {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  &:hover {
    .pic {
      transform: scale(1.02);
      filter: saturate(1.1) sepia(0.1);
      opacity: 0.75;
    }
    .caption {
      background: ${colors.gunmetal};
    }
  }
`;

export function Gallery(props) {
  const pages = map(props.data.allMarkdownRemark.edges, 'node');
  return (
    <Layout subPageTitle='Gallery'>
      <PageContent>
          {/*
          <PageIntro>
              <Salutation>Take a look around at some of my favorite events!</Salutation>
          </PageIntro>
          */}
          <CardContainer>
              {pages.map((page) => {
                  const img = get(page, 'frontmatter.featuredImage.childImageSharp.fluid');

                  return (
                      <Link key={page.fields.slug} to={page.fields.slug}>
                        <Card>
                          <div className='caption'>
                              <div className='title'>{page.frontmatter.title}</div>
                              <div className='type'>{page.frontmatter.eventType}</div>
                          </div>
                            <div className='picClip'>
                              <Img className='pic' fluid={img} />
                            </div>
                        </Card>
                      </Link>
                  );
              })}
          </CardContainer>
          <br /><br/>
          <TestimonialsWidget/>
          <CallToAction
              to='/contact'
              actionText='Contact Me'
              primerText={`Imagine your perfect day`}
          />
      </PageContent>
    </Layout>
  );
}

export const query = graphql`
query MyQuery {
  allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/gallery/"}}, sort: {fields: frontmatter___eventDate, order: DESC}) {
    edges {
      node {
        fields {
            slug
        }
        frontmatter {
          eventType
          title
          featuredImage {
          childImageSharp {
            fluid(quality:100, maxWidth: 1000, maxHeight: 700) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        }
      }
    }
  }
}
`;

export default Gallery;
